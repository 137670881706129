import React from "react"
import { CardElement } from "@stripe/react-stripe-js"

import { FormLabels } from "../../components"

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "tenon, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode: true,
}

function CardSection() {
  return (
    <div>
      <FormLabels label="Card details" />
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  )
}

export default CardSection
