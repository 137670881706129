import React, { useEffect } from "react"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function HoldingPage({
  progress
}) {

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const images = [
    {
      id: 2,
      src: "/Carousel-1.png",
      alt: "Image 2"
    },
    {
      id: 3,
      src: "/Carousel-2.png",
      alt: "Image 3"
    },
    {
      id: 4,
      src: "/Carousel-3.png",
      alt: "Image 4"
    },
    {
      id: 6,
      src: "/Carousel-4.png",
      alt: "Image 6"
    }
  ]

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0
      });
    };

    window.addEventListener('scroll', handleScrollToTop);

    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);

  return (
    <div>

      {progress === 0 &&
        <h3 className="text-center py-3" style={{ fontFamily: "WorkSansExtraBold", color:"#08033D"}}>Building your personalised package...</h3>
      }

      {progress === 1 &&
        <h3 className="text-center py-3" style={{ fontFamily: "WorkSansExtraBold", color:"#08033D"}}>Your Package is Being Built...</h3>
      }

      <div>
        <Slider {...settings} >
          {images.map((item) => (
            <div
              key={item.id}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <img
                img src={item.src} alt={item.alt}
                style={{
                  alignSelf: 'center',
                  maxHeight: '70vh',
                  maxWidth: '100vw',
                  margin: '0 auto'
                }}
              />
            </div>
          ))}
        </Slider>
      </div>

    </div>
  )
}
