import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { DateTime } from "luxon"
import { navigate } from "gatsby"
import {
  AddressLookup,
  FormLine,
  InputSection,
  Dropdown,
  Button,
  Centered1200Wrapper,
  TextWithSubtext,
  Errors,
  FormLabels,
  PageTitle,
} from "../../components"
import { PSROptions } from "../../../../constants"
import Checkbox from "@material-ui/core/Checkbox"
import DropdownWithMultipleSelection from "../components/DropdownWithMultipleSelection"
import {
  useGetAgencies,
  useGetUniversities,
  useSetPersonalInformation,
} from "../../services/group"
import { makeStyles } from "@material-ui/core/styles"

import {
  validateName,
  validateEmail,
  validateMobilePhone,
} from "../../services/errorValidation"
import { validateEnergySelected } from "../../services/errorValidation"


const useStyles = makeStyles({
  checkbox: {
    color: "#000 !important",
    paddingLeft: "0px",
  },
  checkboxLabel: {
    fontSize: "15px",
    fontFamily: "tenon, sans-serif",
    color: "#000 !important",
    "& a": {
      color: "#000 !important",
    },
  },
  warningMessage: {
    marginTop: "16px",
    color: "red",
    fontSize: "12px"
  }
})

export default function PersonalFormContainer({
  city,
  lead,
  group,
  onContinue,
  tenancyInformation,
  setTenancyInformation,
  personalInformation,
  setPersonalInformation,
  primary,
  skipPostPersonalInformation,
  isHomeownerOrRenter,
  hasBroadband,
}) {
  const classes = useStyles()

  React.useEffect(() => {
    document.title = "Bunch - Personal Information"
  }, [])

  const entered =
    tenancyInformation.startDate &&
    parseInt(tenancyInformation.startDate.slice(0, 4)) >= 2020
  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState(null)
  const postPersonalInformation = useSetPersonalInformation()
  const year = new Date().getFullYear()
  const [localErrors, setLocalErrors] = React.useState(null)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  const [showManualAgencyEntry, setShowManualAgencyEntry] = React.useState(
    tenancyInformation.lettingAgencyName ? true : false
  )

  const universitiesQuery = useGetUniversities(city)

  const years = Array.from(new Array(7), (val, index) => index + year)
  const graduationEntered =
    personalInformation.studentBool && personalInformation.graduationYear === ""
      ? false
      : true
  const [
    showManualUniversityEntry,
    setShowManualUniversityEntry,
  ] = React.useState(false)

  const [validationErrors, setValidationErrors] = React.useState({
    address: {
      error: false,
      helperText: "",
    },
    tenancyStart: {
      error: false,
      helperText: "",
    },
    tenancyEnd: {
      error: false,
      helperText: "",
    },
    agencyId: {
      error: false,
      helperText: "",
    },
    lettingAgencyName: {
      error: false,
      helperText: "",
    },
    firstName: {
      error: false,
      helperText: "",
    },
    lastName: {
      error: false,
      helperText: "",
    },
    email: {
      error: false,
      helperText: "",
    },
    number: {
      error: false,
      helperText: "",
    },
    dob: {
      error: false,
      helperText: "",
    },
    moveIn: {
      error: false,
      helperText: "",
    },
    graduationEntered: {
      error: false,
      helperText: "",
    },
    university: {
      error: false,
      helperText: "",
    },
    PSRCategory: {
      error: false,
      helperText: "",
    }
  })

  const validateFieldsOnSubmit = newErrors => {
    if (
      primary &&
      (!tenancyInformation.address ||
        !tenancyInformation.address.addressLine1 ||
        !tenancyInformation.address.postCode ||
        !tenancyInformation.address.city)
    ) {
      newErrors.address = { error: true, helperText: "Address is required" }
    } else {
      newErrors.address = { error: false }
    }

    if (tenancyInformation.startDate === null) {
      newErrors.tenancyStart = {
        error: true,
        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
          } start date is required`,
      }
    }

    if (tenancyInformation.endDate === null) {
      newErrors.tenancyEnd = {
        error: true,
        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
          } end date is required`,
      }
    }

    if (!personalInformation.firstName) {
      newErrors.firstName = {
        error: true,
        helperText: "First name is required",
      }
    }

    if (!personalInformation.lastName) {
      newErrors.lastName = { error: true, helperText: "Last name is required" }
    }

    if (
      personalInformation.firstName &&
      !validateName(personalInformation.firstName)
    ) {
      newErrors.firstName = {
        error: true,
        helperText: "Please enter a valid name",
      }
    }

    if (
      personalInformation.lastName &&
      !validateName(personalInformation.lastName)
    ) {
      newErrors.lastName = {
        error: true,
        helperText: "Please enter a valid name",
      }
    }

    if (personalInformation.lastName === "") {
      newErrors.lastName = { error: true, helperText: "Last name is required" }
    }

    if (!personalInformation.dateOfBirth) {
      newErrors.dob = { error: true, helperText: "Date of birth is required" }
    }

    if (
      personalInformation.phoneNumber &&
      !validateMobilePhone(personalInformation.phoneNumber)
    ) {
      newErrors.number = {
        error: true,
        helperText: "Please enter a valid phone number",
      }
    }

    if (!personalInformation.phoneNumber) {
      newErrors.number = {
        error: true,
        helperText: "Mobile phone number is required",
      }
    }

    if (!personalInformation.email) {
      newErrors.email = { error: true, helperText: "Email address is required" }
    }

    if (
      personalInformation.email &&
      !validateEmail(personalInformation.email)
    ) {
      newErrors.email = {
        error: true,
        helperText: "Please enter a valid email address",
      }
    }

    if (personalInformation.isVulnerablePerson && (personalInformation.PSRCategory === undefined || personalInformation.PSRCategory.length === 0)) {
      newErrors.PSRCategory = {
        error: true,
        helperText: "Please select the reason"
      }
    }

    setValidationErrors(newErrors)
    return newErrors
  }

  const previouslyEnteredContractLength = group && group.quote.contractLength
  let newContractLength = null

  if (tenancyInformation.startDate && tenancyInformation.endDate) {
    var differenceInDays = Math.floor(
      (Date.parse(tenancyInformation.endDate) -
        Date.parse(tenancyInformation.startDate)) /
      86400000
    )
    newContractLength = Math.round(
      Math.max(9, Math.min(12, Math.ceil(differenceInDays / (365 / 12))))
    )
  }

  let lastAvailableDateForResidential = DateTime.local().plus({ days: 30 })
  const contractStartMessage = "IMPORTANT - Choosing this date, you'll be on supply during your cooling off window, which means you'll be liable for any energy changes during this time, even if you cancel the contract. If you don't want this please select a date at least 15 days away."

  function checkContractStartDate() {
    const { startDate } = tenancyInformation;
    if (!startDate) return false;

    const today = new Date();
    const earliest = new Date(today);
    earliest.setDate(today.getDate() + 14);
    const latest = new Date(today);
    latest.setDate(today.getDate() + 30);

    const contactStartDate = new Date(tenancyInformation.startDate);
    return (contactStartDate > earliest && contactStartDate <= latest);

  }

  function checkTenancyStartDate() {
    const { startDate } = tenancyInformation;
    if (!startDate) return false;

    const today = new Date();
    const earliest = new Date(today);
    const latest = new Date(today);
    latest.setDate(today.getDate() + 14);

    const contactStartDate = new Date(tenancyInformation.startDate);
    return (contactStartDate > earliest && contactStartDate <= latest);

  }
  useEffect(() => {
    if (!isHomeownerOrRenter && new Date(tenancyInformation.startDate) < new Date()) {
      let vErrors = {
        ...validationErrors,
        tenancyStart: { ...validationErrors.tenancyStart },
      }
      vErrors.tenancyStart = {
        error: true,
        helperText: `We can only accept contracts starting in the next 10 months`,
      }
      setValidationErrors(vErrors)

    }
  }, [tenancyInformation.startDate])

  return (
    <>
      <Centered1200Wrapper>
        <div style={{ padding: "0 20px" }}>
          <PageTitle
            style={{ textAlign: "left" }}
          >
            <div className="quotingTool-pageTitle">Details</div>
          </PageTitle>
          <div
            style={{
              fontWeight: "400",
              color: "#000",
              opacity: "0.54",
              marginBottom: 20,
            }}
            className="quotingTool-pageDescription"
          >
            Enter your details to create your account with Bunch.
          </div>
        </div>
        {primary && (
          <>
            <FormLine>
              <InputSection
                error={validationErrors.tenancyStart.error}
                helperText={validationErrors.tenancyStart.helperText}
                label={`${isHomeownerOrRenter ? "Contract" : "Tenancy"} start`}
                popupText="This is the date your legal contract with your agent starts"
                type="date"
                submitAttempted={submitAttempted}
                inputProps={{
                  min: DateTime.local().startOf('day').toFormat("yyyy-MM-dd"),
                  max: isHomeownerOrRenter
                    ? lastAvailableDateForResidential.toFormat("yyyy-MM-dd")
                    : DateTime.local()
                      .plus({ months: 10 })
                      .toFormat("yyyy-MM-dd"),
                }}
                value={tenancyInformation.startDate}
                placeholder="YYYY-MM-DD"
                onChange={e => {
                  let vErrors = {
                    ...validationErrors,
                    tenancyStart: { ...validationErrors.tenancyStart },
                  }
                  if (e.target.value === null) {
                    vErrors.tenancyStart = {
                      error: true,
                      helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
                        } start date is required`,
                    }
                  } else {
                    var d = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                    if (!d.isValid) {
                      vErrors.tenancyStart = {
                        error: true,
                        helperText: `Please enter valid ${isHomeownerOrRenter ? "contract" : "tenancy"
                          } start date in the format YYYY-MM-DD`,
                      }
                    } else if (
                      !isHomeownerOrRenter &&
                      (d < DateTime.local().startOf('day') ||
                        d > DateTime.local().plus({ months: 10 }))
                    ) {
                      vErrors.tenancyStart = {
                        error: true,
                        helperText: `We can only accept contracts starting in the next 10 months`,
                      }
                    } else if (
                      isHomeownerOrRenter &&
                      (d < DateTime.local().startOf('day') ||
                        d > lastAvailableDateForResidential)
                    ) {
                      vErrors.tenancyStart = {
                        error: true,
                        helperText: `We can only accept contracts starting in the next 30 days.`,
                      }
                    } else {
                      vErrors.tenancyStart = { error: false, helperText: "" }
                    }
                  }
                  setValidationErrors(vErrors)
                  setTenancyInformation({
                    startDate: e.target.value,
                  })
                }}
                onBlur={e => {
                  setTenancyInformation({
                    startDate: e.target.value,
                    endDate: DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                      .plus({ months: group.quote.contractLength })
                      .minus({ days: 1 })
                      .toISODate(),
                  })
                  setPersonalInformation({
                    moveInDate: e.target.value,
                  })
                  let vErrors = {
                    ...validationErrors,
                    tenancyEnd: { error: false, helperText: "" },
                  }
                  setValidationErrors(vErrors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {(tenancyInformation.startDate && isHomeownerOrRenter && checkContractStartDate()) &&
                <div className={classes.warningMessage}>{contractStartMessage}</div>
              }
              {(tenancyInformation.startDate && !isHomeownerOrRenter && checkTenancyStartDate()) &&
                <div className={classes.warningMessage}>{contractStartMessage}</div>
              }
            </FormLine>
            <FormLine>
              <InputSection
                error={validationErrors.tenancyEnd.error}
                helperText={validationErrors.tenancyEnd.helperText}
                label={`${isHomeownerOrRenter ? "Contract" : "Tenancy"} end`}
                popupText="This is the date your legal contract with your agent ends"
                type="date"
                value={tenancyInformation.endDate}
                placeholder="YYYY-MM-DD"
                disabled={!entered}
                submitAttempted={submitAttempted}
                inputProps={{
                  min: DateTime.fromISO(tenancyInformation.startDate)
                    .plus({ months: 9 })
                    .toFormat("yyyy-MM-dd"),
                  max: DateTime.fromISO(tenancyInformation.startDate)
                    .plus({ months: 13 })
                    .toFormat("yyyy-MM-dd"),
                }}
                onChange={e => {
                  let vErrors = {
                    ...validationErrors,
                    tenancyEnd: { ...validationErrors.tenancyEnd },
                  }
                  if (e.target.value === "") {
                    vErrors.tenancyEnd = {
                      error: true,
                      helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
                        } end date is required`,
                    }
                  } else {
                    var d = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                    if (!d.isValid) {
                      vErrors.tenancyEnd = {
                        error: true,
                        helperText: `Please enter valid ${isHomeownerOrRenter ? "contract" : "tenancy"
                          } end date in the format YYYY-MM-DD`,
                      }
                    } else if (
                      DateTime.fromISO(tenancyInformation.startDate) > d
                    ) {
                      vErrors.tenancyEnd = {
                        error: true,
                        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
                          } end should be later than ${isHomeownerOrRenter ? "contract" : "tenancy"
                          } start`,
                      }
                    } else if (
                      d <
                      DateTime.fromISO(tenancyInformation.startDate).plus({
                        months: 8.5,
                      }) ||
                      d >
                      DateTime.fromISO(tenancyInformation.startDate).plus({
                        months: 13,
                      })
                    ) {
                      vErrors.tenancyEnd = {
                        error: true,
                        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
                          } end date should be between 9 and 13 months from the ${isHomeownerOrRenter ? "contract" : "tenancy"
                          } start date`,
                      }
                    } else {
                      vErrors.tenancyEnd = { error: false, helperText: "" }
                    }
                  }
                  setTenancyInformation({ endDate: e.target.value })
                  setValidationErrors(vErrors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormLine>
            <div style={{ padding: "0 20px" }}>
              <div style={{ color: "#000" }}>
                If you don't know the exact{" "}
                {isHomeownerOrRenter ? "contract" : "tenancy"} end date, enter
                an approximate one. You will be able to edit it later.
              </div>{" "}
              {newContractLength &&
                previouslyEnteredContractLength !== newContractLength && (
                  <div
                    style={{ marginTop: 20, fontWeight: "bold", color: "#000" }}
                  >
                    ⚠️ You've selected a
                    {previouslyEnteredContractLength === 11 && "n"}{" "}
                    {previouslyEnteredContractLength} month tenancy, but the
                    dates you've provided are a{newContractLength === 11 && "n"}{" "}
                    {newContractLength} month period. Your monthly prices will
                    be adjusted to be{" "}
                    {newContractLength < previouslyEnteredContractLength
                      ? "higher"
                      : "lower"}{" "}
                    in order to match your move-in and move-out dates.
                  </div>
                )}
            </div>
          </>
        )}
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.firstName.error}
            helperText={validationErrors.firstName.helperText}
            label="First name"
            popupText="We’ll register you with this name"
            value={personalInformation.firstName}
            submitAttempted={submitAttempted}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                firstName: { ...validationErrors.firstName },
              }
              if (e.target.value === "") {
                vErrors.firstName = {
                  error: true,
                  helperText: "First name is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.firstName = {
                  error: true,
                  helperText: "Please enter a valid name",
                }
              } else {
                vErrors.firstName = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ firstName: e.target.value })
            }}
          />
        </FormLine>}
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.lastName.error}
            helperText={validationErrors.lastName.helperText}
            label="Last name"
            value={personalInformation.lastName}
            submitAttempted={submitAttempted}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                lastName: { ...validationErrors.lastName },
              }
              if (e.target.value === "") {
                vErrors.lastName = {
                  error: true,
                  helperText: "Last name is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.lastName = {
                  error: true,
                  helperText: "Please enter a valid name",
                }
              } else {
                vErrors.lastName = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ lastName: e.target.value })
            }}
          />
        </FormLine>}
        <FormLine>
          <InputSection
            name={"dateOfBirth"}
            error={validationErrors.dob.error}
            helperText={validationErrors.dob.helperText}
            label="Date of birth"
            popupText="We use this for data security if you phone in to speak to us"
            type="date"
            value={personalInformation.dateOfBirth}
            submitAttempted={submitAttempted}
            placeholder="YYYY-MM-DD"
            inputProps={{
              min: DateTime.fromFormat("1900-01-01", "yyyy-MM-dd").toFormat(
                "yyyy-MM-dd"
              ),
              max: DateTime.local().plus({ years: -16 }).toFormat("yyyy-MM-dd"),
            }}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                dob: { ...validationErrors.dob },
              }
              var cutoffDate = DateTime.fromISO("1900-01-01");

              if (e.target.value === "" || e.target.value === null) {
                vErrors.dob = {
                  error: true,
                  helperText: "Date of birth is required",
                }
              } else if (DateTime.fromISO(e.target.value) >= DateTime.local()) {
                vErrors.dob = {
                  error: true,
                  helperText: "Please enter valid date of birth",
                }
              } else {
                var d = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                if (!d.isValid) {
                  vErrors.dob = {
                    error: true,
                    helperText:
                      "Please enter valid date of birth in the format DD-MM-YYYY",
                  }
                } else {
                  if (d < cutoffDate) {
                    vErrors.dob = {
                      error: true,
                      helperText:
                        "Please enter birth date later than January 1, 1900.",
                    }
                  } else if (d > DateTime.local().plus({ years: -18 })) {
                    vErrors.dob = {
                      error: true,
                      helperText:
                        "You must be at least 18 years old to use this service.",
                    }
                  } else {
                    vErrors.dob = { error: false, helperText: "" }
                  }
                }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ dateOfBirth: e.target.value })
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormLine>
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.number.error}
            helperText={validationErrors.number.helperText}
            label="Mobile phone number"
            popupText="So we can SMS you with details of your contract if needed"
            value={personalInformation.phoneNumber}
            submitAttempted={submitAttempted}
            // type="phone"
            onChange={e => {
              let val = e.target.value
              let vErrors = {
                ...validationErrors,
                number: { ...validationErrors.number },
              }
              if (val === "") {
                vErrors.number = {
                  error: true,
                  helperText: "Mobile phone number is required",
                }
              } else if (!validateMobilePhone(val)) {
                vErrors.number = {
                  error: true,
                  helperText: "Please enter a valid mobile phone number",
                }
              } else {
                vErrors.number = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ phoneNumber: val })
            }}
          />
        </FormLine>}
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.email.error}
            helperText={validationErrors.email.helperText}
            label="Email"
            popupText="So we can email you with details of your contract"
            value={personalInformation.email}
            submitAttempted={submitAttempted}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                email: { ...validationErrors.email },
              }
              if (e.target.value === "") {
                vErrors.email = {
                  error: true,
                  helperText: "Email address is required",
                }
              } else if (!validateEmail(e.target.value)) {
                vErrors.email = {
                  error: true,
                  helperText: "Please enter a valid email",
                }
              } else {
                vErrors.email = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ email: e.target.value })
            }}
          />
        </FormLine>}

        <div
          style={{
            display: "flex",
            flexDirection: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 40,
            padding: "0 20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Checkbox
                color="primary"
                id="agree"
                name="agree"
                checked={personalInformation.isVulnerablePerson}
                value={personalInformation.isVulnerablePerson}
                onChange={e => {

                  setPersonalInformation({
                    ...personalInformation,
                    PSRCategory: [],
                    isVulnerablePerson: !personalInformation.isVulnerablePerson
                  })
                }}
                className={classes.checkbox}
              />
              <div
                htmlFor="agree"
                style={{
                  fontSize: "15px",
                  fontFamily: "tenon, sans-serif",
                  color: "#000",
                }}
                className={classes.checkboxLabel}
              >
                Are you registered with the Priority Services Register? Please
                tick here and select the reason from the list below.
              </div>
            </div>
          </div>
        </div>
        {personalInformation.isVulnerablePerson &&
          <FormLine>
            <DropdownWithMultipleSelection
              label={"Please select the reason from the list below"}
              labels={PSROptions} multiple={true}
              setValue={payload => {
                setPersonalInformation({ PSRCategory: payload })
                if (payload.length > 0) {
                  setValidationErrors({ ...validationErrors, PSRCategory: { error: false, helperText: "" } })
                }
              }}
              error={validationErrors.PSRCategory.error}
              helperText={validationErrors.PSRCategory.helperText}
              value={personalInformation.PSRCategory}
              placeholder={(personalInformation.PSRCategory === undefined || personalInformation.PSRCategory.length === 0) && "Select the reason"} />
          </FormLine>
        }
        <div
          style={{
            marginTop: "20px",
            padding: "0 20px",
          }}
        >
          <Button
            className={clsx(
              "SignUp-Step-02-Details",
              "quotingTool-submitButton"
            )}
            fullWidth
            onClick={() => {
              if (submitting) {
                return false
              }

              setSubmitAttempted(true)

              // validate everything exists!

              const newErrors = validateFieldsOnSubmit(
                JSON.parse(JSON.stringify(validationErrors))
              )
              setLocalErrors(null)
              let localErrors = []
              let hasError = false
              Object.values(newErrors).forEach(value => {
                if (value.error) {
                  localErrors.push(value.helperText)
                  hasError = true
                }
              })

              console.log(localErrors);

              if (hasError) {
                setLocalErrors([
                  "You need correct the issues above before continuing",
                ])
                return false
              }

              setSubmitting(true)
              setErrors(null)

              if (skipPostPersonalInformation) {
                onContinue()
              } else {
                postPersonalInformation({
                  leadId: lead.id,
                  groupId: group.id,
                  startDate: tenancyInformation.startDate,
                  endDate: tenancyInformation.endDate,
                  isTenancyStartDateEstimate:
                    tenancyInformation.isTenancyStartDateEstimate,
                  isTenancyEndDateEstimate:
                    tenancyInformation.isTenancyEndDateEstimate,
                  address: tenancyInformation.address,
                  dateOfBirth: personalInformation.dateOfBirth,
                  graduationYear: personalInformation.graduationYear
                    ? personalInformation.graduationYear.toString()
                    : "",
                  student: personalInformation.studentBool,
                  moveInDate: personalInformation.moveInDate,
                  moveInDateEstimate: personalInformation.moveInDateEstimate,
                  firstName: personalInformation.firstName,
                  lastName: personalInformation.lastName,
                  phoneNumber: personalInformation.phoneNumber,
                  email: personalInformation.email.toLowerCase().trim(),
                  university: personalInformation.university,
                  isVulnerablePerson: personalInformation.isVulnerablePerson,
                }).then(r => {
                  if (r.isSuccess) {
                    setSubmitting(false)
                    onContinue()
                  } else {
                    setSubmitting(false)
                    setErrors(r.errors)
                  }
                })
              }
            }}
          >
            {submitting ? "Saving..." : "Continue"}
          </Button>
          <Errors>{localErrors || errors}</Errors>
        </div>
      </Centered1200Wrapper>
    </>
  )
}
