import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
} from "@material-ui/core"
import clsx from "clsx"

import FormLabels from "./FormLabels"

const useStyles = makeStyles({
  primary: {
    color: "#FAFAFA",
    "& .MuiButtonBase-root": {
      color: "#FAFAFA",
    },
  },
})

export default function RadioButton({
  value,
  onChange,
  fieldIndex,
  label,
  description,
  popupText,
  marks,
  white,
}) {
  const classes = useStyles()

  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      <FormLabels
        label={label}
        description={description}
        popupText={popupText}
        white={white}
        fieldIndex={fieldIndex}
      />
      <MuiFormControl>
        <MuiRadioGroup
          name="radio-buttons-group"
          onChange={e => onChange(e.target.value)}
          value={value}
        >
          {marks.map(mark => (
            <MuiFormControlLabel
              className={clsx(classes.primary)}
              value={mark.value}
              control={<MuiRadio />}
              label={mark.label}
            />
          ))}
        </MuiRadioGroup>
      </MuiFormControl>
    </div>
  )
}
