import React, { useState, useCallback } from "react"
import AddressSearch from "react-loqate"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"

import { validatePostcode } from "../services/errorValidation"
import {
  lookupAddresses as apiLookupAddresses,
  logError as apiLogError,
} from "../../../services/tenantsApiV2"

import Dropdown from "./Dropdown"

import {
  Button,
  FormLine,
  TextInput,
  InputSection,
  ManualAddressEntry,
  Errors,
} from "./"

// to use the default styles for the default components
import "react-loqate/dist/react-loqate.cjs.development.css"

const useStyles = makeStyles({
  addressSearchInput: {
    width: "100%",
    backgroundColor: "#FAFAFA",
  },
  clearIcon: {
    position: "absolute",
    right: 30,
    bottom: 13,
    cursor: "pointer",
  },
  dateInput: {
    width: "100%",
  },
  addressList: {
    zIndex: 10000,
    padding: 0,
    maxHeight: 200,
    overflowY: "scroll",
    "& .react-loqate-list-item:nth-child(even)": {
      backgroundColor: "#ececec",
    },
  },
  addressListItem: {
    padding: "10px",
  },
  manualAddressEntry: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
      color: "#FAFAFA",
      boxShadow: "none",
    },
  },
  addressLookupResponse: {
    backgroundColor: "#FAFAFA",
    height: 300,
    overflowY: "scroll",
    marginTop: 10,
    "& div:nth-child(even)": {
      backgroundColor: "#ececec",
    },
  },
  addressLookupResponseAddress: {
    padding: "10px",
    cursor: "pointer",
  },
})

export default function AddressLookup({
  fieldIndex,
  label,
  description,
  required,
  value,
  onChange,
  submitAttempted,
  error,
}) {
  const classes = useStyles()

  const [postCode, setPostCode] = useState("")
  const [addresses, storeAddresses] = useState(null)
  const [lookingUpAddresses, setLookingUpAddresses] = useState(false)
  const [showManualAddressEntry, setShowManualAddressEntry] = useState(false)
  const [localError, setLocalError] = useState("")

  const lookupAddresses = async () => {
    try {
      if (!validatePostcode(postCode.trim())) {
        setLocalError("Please enter a valid UK postcode")
        apiLogError({
          type: "Info",
          name: "PropertyFinder_Address_Lookup",
          title: "An invalid UK postcode was attempted",
          metadata: {
            postCode,
          },
        })
        return
      }

      setLocalError("")
      setLookingUpAddresses(true)
      const data = await apiLookupAddresses(postCode.trim())

      if (data && data.value) {
        storeAddresses(data.value)
      } else {
        storeAddresses([])
      }
    } catch (e) {
      console.log(e)
    }
    setLookingUpAddresses(false)
  }

  const manualAddressDialog = (
    <Dialog
      open={showManualAddressEntry}
      BackdropProps={{
        style: {
          backgroundColor: "#222222eb",
        },
      }}
      className={classes.manualAddressEntry}
    >
      <div
        style={{
          padding: 20,
          background: "#FAFAFA",
          width: "100%",
          borderRadius: 5,
          border: "1px solid grey",
          overflowY: "scroll",
        }}
      >
        <ManualAddressEntry
          ignorePostcodeValidation={true}
          isShown={showManualAddressEntry}
          value={value || {}}
          onChange={payload => {
            onChange(payload)
            setShowManualAddressEntry(false)
          }}
          onCancel={() => {
            setShowManualAddressEntry(false)
          }}
        />
      </div>
    </Dialog>
  )

  const cantSeeAddress = (
    <div
      style={{
        cursor: "pointer",
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "10px",
        color: "#000",
      }}
      onClick={() => setShowManualAddressEntry(!showManualAddressEntry)}
      onKeyPress={() => setShowManualAddressEntry(!showManualAddressEntry)}
      tabIndex="0"
      role="button"
    >
      Can't see your address?{" "}
      <span
        style={{
          cursor: "pointer",
          fontWeight: "bold",
          textDecorationLine: "underline",
        }}
      >
        Click here to enter it manually
      </span>
    </div>
  )

  if (value && value.addressLine1 && value.postCode) {
    return (
      <div style={{ width: "100%" }}>
        {manualAddressDialog}
        <div style={{ position: "relative", width: "100%" }}>
          <InputSection
            fieldIndex={fieldIndex}
            label={label}
            description={description}
            type="text"
            value={[
              value.addressLine1,
              value.addressLine2,
              value.city,
              value.postCode,
              value.country,
            ]
              .filter(val => val)
              .join(", ")}
            required={required}
            disabled
            onClear={() => onChange(null)}
          />
          {/* <ClearIcon
            onClick={() => onChange(null)}
            className={classes.clearIcon}
          /> */}
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: "100%" }}>
      {manualAddressDialog}
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: 250 }}>
          <InputSection
            fieldIndex={fieldIndex}
            label={label}
            description={description}
            type="text"
            value={postCode}
            required={required}
            onChange={e => setPostCode(e.target.value)}
            placeholder={"Postcode"}
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <Button
            onClick={() => lookupAddresses()}
            loading={lookingUpAddresses}
            className="quotingTool-submitButton"
            disabled={!postCode}
          >
            Lookup address
          </Button>
        </div>
      </div>
      {addresses && !lookingUpAddresses && (
        <div className={classes.addressLookupResponse}>
          {addresses.map(address => (
            <div
              onClick={() => onChange({ ...address, manuallyEntered: false })}
              className={classes.addressLookupResponseAddress}
            >
              {address.metadata.fullAddress}
            </div>
          ))}
        </div>
      )}
      {addresses && !lookingUpAddresses && cantSeeAddress}
      {(localError || submitAttempted) && (
        <Errors style={{ marginTop: 0, marginTop: 10 }}>
          {localError || error}
        </Errors>
      )}
    </div>
  )
}
