import React, { useState } from "react"
import Button from "../../../components/button/Button"
import PageWrapper from "../../../components/PageWrapper"
import TextInput from "../../../components/textInput/TextInput"
import { TrustpilotFooter } from "../components"
import { getQuoteByEmail } from "../services/quote"

export default function FindQuotePage({
  location
}) {
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [gettingQuote, setGettingQuote] = useState(false);

  const getQuote = async () => {
    setError(null)
    setSuccess(null)

    if (email) {
      setGettingQuote(true);
      try {
        const getQuoteResponse = await getQuoteByEmail(email)

        if (!getQuoteResponse.isSuccess) {
          setError("Could not find quote for the email address entered")
        } else {
          setSuccess(`An email has been sent to ${email} with a link to your quote`)
        }

      } catch (e) {
        console.log(e)
        setError("Could not find quote for the email address entered")
      } finally {
        setGettingQuote(false);
      }

    } else {
      setError("An email address needs to be entered to find your quote")
    }
  }

  return (
    <>
      <PageWrapper
        location={location}
        quoteViewMode={true}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ fontFamily: "tenon, sans-serif", padding: "40px 60px 0 60px", textAlign: "center", fontSize: "x-large" }}>Enter your email address to locate your existing quote:</div>

          <div
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center"
            }}
          >
            <div style={{ marginBottom: "30px" }}>
              <TextInput
                type="text"
                placeholder="Email address"
                value={email}
                style={{ width: "400px" }}
                onChange={e => {
                  setEmail(e)
                }}
              />
            </div>

            <Button
              title="Find Quote"
              style={{
                width: "100%",
                display: "inline-flex",
                minWidth: "100px",
                maxWidth: 150,
                backgroundColor: "#FFFFFF",
                border: "1px solid #021A3A",
                color: "#021A3A",
                marginBottom: "30px"
              }}
              onClick={getQuote}
              submitting={gettingQuote}
              white
            />
            <div style={{ textAlign: "center", color: "green" }}>{success}</div>
            <div style={{ textAlign: "center", color: "red" }}>{error}</div>
          </div>

        </div>
        <TrustpilotFooter />
      </PageWrapper>
    </>
  )
}
