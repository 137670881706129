import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

import PageWrapper from "../../../components/PageWrapper"

import { InviteFormContainer } from "./containers"

import { Center } from "../components"

import { useGetGroup, useAddNewHousemate } from "../services/group"

function LeadPage({ location, groupId }) {
  const frontEndUrl = window.location.origin

  const [inviteDetails, setInviteDetails] = React.useState(
    Array(1).fill({
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      isMissingInformation: false,
    })
  )

  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState([""])
  const addNewHousemate = useAddNewHousemate()

  const groupQuery = useGetGroup(groupId)

  const group = groupQuery.data && groupQuery.data.value.group

  if (groupQuery.isLoading) {
    return (
      <PageWrapper location={location} quoteViewMode={true}>
        <Center>
          <CircularProgress />
        </Center>
      </PageWrapper>
    )
  }

  if (groupQuery.errors || !groupQuery.data.isSuccess) {
    return (
      <PageWrapper location={location} quoteViewMode={true}>
        Unable to load tenant.
      </PageWrapper>
    )
  }

  function buttonOnClick() {
    if (submitting) {
      return false
    }

    setSubmitting(true)
    setErrors(null)
    addNewHousemate({
      groupId: group.id,
      invites: inviteDetails.map(lead => {
        return {
          email: lead.email.toLowerCase().trim(),
          number: lead.number,
          firstName: lead.firstName,
          lastName: lead.lastName,
          isMissingInformation: lead.isMissingInformation,
        }
      }),
    }).then(r => {
      if (r.isSuccess) {
        window.location.replace(
          frontEndUrl + "/tenants/app/signup/complete/" + group.id + "/"
        )
        setSubmitting(false)
      } else {
        setSubmitting(false)
        setErrors(r.errors)
      }
    })
  }

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#FAFAFA"
    >
      <InviteFormContainer
        inviteDetails={inviteDetails}
        setInviteDetails={e => setInviteDetails(e)}
        groupId={groupId}
        groupLeadEmail={group.primaryLead.email}
        groupLeadPhoneNumber={group.primaryLead.phone}
        buttonOnClick={buttonOnClick}
        buttonSubmitting={submitting}
        errors={errors}
      />
    </PageWrapper>
  )
}

export default LeadPage
