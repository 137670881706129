import React, { useState } from "react"
import clsx from "clsx"
import {
  Centered1200Wrapper,
  TextWithSubtext,
  Button,
  Marker,
} from "../../components"
import { makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"

const useStyles = makeStyles(theme => ({
  signUpOptions: {
    marginBottom: 100,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: 50,
    },
  },
  signUpOption: {
    padding: 20,
    width: "calc(50% - 5px)",
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",
    cursor: "pointer",
    color: "#FAFAFA",
    position: "relative",
    borderRadius: 10,
    border: "2px solid #fff",
    "-webkit-transition": "border 500ms ease-out",
    "-moz-transition": "border 500ms ease-out",
    "-o-transition": "border 500ms ease-out",
    transition: "border 500ms ease-out",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
  signUpOptionLeft: {
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      marginBottom: "50px",
    },
  },
  signUpOptionRight: {
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  signUpOptionSelected: {
    border: "2px solid #f7c829",
  },
  signUpOptionTitle: {
    fontSize: 23,
    fontWeight: "bold",
    marginBottom: 20,
  },
  signUpOptionDetails: {
    border: "1px solid #2eb664",
    borderRadius: 5,
    textAlign: "left",
    lineHeight: "25px",
  },
  signUpFeatures: {
    minHeight: 200,
    marginBottom: 20,
  },
  signUpFeature: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cost: {
    fontSize: 17,
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
  },
  previousCost: {
    textDecoration: "line-through",
    fontWeight: "normal",
    marginRight: 10,
  },
  selectOption: {
    textAlign: "center",
  },
  selectOptionUnselectedIcon: {
    fontSize: "41px",
    color: "#b7b7b7",
    cursor: "pointer",
  },
  selectOptionSelectedIcon: {
    fontSize: "41px",
    cursor: "pointer",
    color: "white",
  },
  checkIconWrapper: {
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: -25,
    left: 0,
  },
  checkIcon: {
    height: 50,
    width: 50,
    background: "#f7c829",
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  addToPackageWrapper: {
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: -25,
    left: 0,
  },
  addToPackage: {
    height: 50,
    backgroundColor: "#FAFAFA",
    padding: "0px 20px",
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2eb664",
    cursor: "pointer",
  },
  featureIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    color: "#97FF7F",
    border: "1px solid #97FF7F",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
}))

export default function SignUpChoiceContainer({
  onContinue,
  queueJumpFeatures,
  standardFeatures,
}) {
  const classes = useStyles()

  const [selected, setSelected] = useState(false)

  return (
    <div>
      <Centered1200Wrapper>
        <div>
          <div className={classes.signUpOptions}>
            <div
              className={clsx(classes.signUpOption, classes.signUpOptionLeft, {
                [classes.signUpOptionSelected]: selected === "queue-jump",
              })}
              onClick={() => setSelected("queue-jump")}
            >
              <div className={classes.signUpOptionTitle}>Queue Jump</div>
              <div className={clsx(classes.signUpOptionDetails)}>
                <div className={classes.signUpFeatures}>
                  {queueJumpFeatures.split("\n").map(feature => (
                    <div className={classes.signUpFeature}>
                      <span className={classes.featureIcon}>
                        <CheckIcon style={{ fontSize: 15 }} />
                      </span>{" "}
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.cost}>
                <span className={classes.previousCost}>£10</span>Free this week
              </div>
              <SelectedOptionIndicator selected={selected === "queue-jump"} />
            </div>
            <div
              className={clsx(classes.signUpOption, classes.signUpOptionRight, {
                [classes.signUpOptionSelected]: selected === "standard",
              })}
              onClick={() => setSelected("standard")}
            >
              <div className={classes.signUpOptionTitle}>Standard</div>
              <div className={clsx(classes.signUpOptionDetails)}>
                <div className={classes.signUpFeatures}>
                  {standardFeatures.split("\n").map(feature => (
                    <div className={classes.signUpFeature}>
                      <span className={classes.featureIcon}>
                        <CheckIcon style={{ fontSize: 15 }} />
                      </span>{" "}
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
              <SelectedOptionIndicator selected={selected === "standard"} />
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Button
              onClick={() => onContinue(selected)}
              style={{ width: "100%", marginBottom: 20 }}
              disabled={!selected}
              className="quotingTool-submitButton"
            >
              Continue
            </Button>
          </div>
        </div>
      </Centered1200Wrapper>
    </div>
  )
}

const SelectedOptionIndicator = ({ selected }) => {
  const classes = useStyles()

  if (selected) {
    return (
      <div className={classes.checkIconWrapper}>
        <div
          className={clsx(
            classes.checkIcon,
            "quotingTool-serviceSelectedCheck"
          )}
        >
          <CheckIcon style={{ color: "#081938", fontSize: 30 }} />
        </div>
      </div>
    )
  } else {
    return (
      <div className={classes.addToPackageWrapper}>
        <div
          className={clsx(
            classes.addToPackage,
            "quotingTool-serviceSelectedCheck"
          )}
        >
          Select
        </div>
      </div>
    )
  }
}
