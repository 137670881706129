export const PSROptions = [
    "Nebuliser and apnoea monitor",
    "Heart, lung and ventilator",
    "Dialysis, feeding pump and automated medication",
    "Oxygen concentrator",
    "Blind",
    "Partially sighted",
    "Stair lift, hoist, electric bed",
    "Pensionable age",
    "Physical Impairment",
    "Unable to communicate in English",
    "Developmental condition",
    "Unable to answer door",
    "Dementia(s)/Cognitive impairment",
    "Chronic/serious illness",
    "Medically dependent showering/bathing",
    "Careline/telecare system",
    "Medicine refrigeration",
    "Oxygen use",
    "Poor sense of smell/taste",
    "Restricted hand movement",
    "Families with young children 5 or under",
    "Mental health",
    "Additional presence preferred",
    "Temporary - Life changes",
    "Temporary - Post hospital recovery",
    "Temporary - Young adult householder",
    "Hearing impairment (inc. Deaf)",
    "Speech impairment",
    "Water dependent"
  ];