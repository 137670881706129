export const roundNum = num => Math.round(num)

export function toPoundFormat(number) {
  if (number === 0) return "£0.00"
  if (!number) return

  return (
    "£" +
    (roundNum(number) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    })
  )
}

export function convertToMonthly(number) {
  return roundNum((roundNum(number) * 52) / 12)
}

export function currencyToNumber(currencyString) {
  return Number(currencyString.replace(/[^0-9.-]+/g, ''))
}

export function getServiceAllowance(
  service,
  allowance,
  quotingToolMultipliers,
  tenancyDetails
) {
  let serviceAllowance = service.allowance

  var allowanceCost = 0

  var serviceMultipliers =
    quotingToolMultipliers &&
    quotingToolMultipliers.length > 0 &&
    quotingToolMultipliers[0].filter(
      quotingToolMultiplier =>
        quotingToolMultiplier.productId.toString() === service.id.toString() &&
        tenancyDetails[quotingToolMultiplier.fieldName] &&
        tenancyDetails[quotingToolMultiplier.fieldName].toString() ===
        quotingToolMultiplier.fieldValue
    )

  for (let j = 0; j < serviceMultipliers.length; j++) {
    serviceAllowance +=
      (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
      serviceAllowance
  }

  if (allowance) {
    allowanceCost += serviceAllowance * (allowance / 100)
  }

  serviceAllowance += allowanceCost

  return Math.round(serviceAllowance).toLocaleString()
}


export function checkIfServiceIsInPackage(packageDetails, serviceId) {
  return (
    packageDetails &&
    packageDetails.find(
      packageService => packageService.serviceId === serviceId
    )
  )
}

export function calculatePrices(
  quote,
  matchingQuotingToolVersion,
  tenancyDetails,
  isHomeownerOrRenter,
  unlimitedPackageSelected
) {
  let weeklyTotal = 0
  let monthlyTotal = 0
  let yearlyTotal = 0
  let services = []

  const quotingToolMultipliers = matchingQuotingToolVersion
    ? matchingQuotingToolVersion.quotingToolPages
      .map(quotingToolPage =>
        quotingToolPage.quotingToolPageFields
          .filter(
            quotingToolField =>
              quotingToolField.quotingToolMultipliers.length > 0
          )
          .map(quotingToolField => quotingToolField.quotingToolMultipliers)
      )
      .flat()
    : []

  var tenancyDetails = JSON.parse(quote.tenancyDetails)
  var packageDetails = JSON.parse(quote.packageDetails)

  packageDetails.forEach(packageService => {
    var matchingProduct = getMatchingProduct(
      quote.services || quote.Packages[0].Services,
      packageService.serviceId
    )

    if (matchingProduct) {

      if (matchingProduct.freeWithUnlimited && unlimitedPackageSelected) {
        services.push({
          displayOrder: matchingProduct.displayOrder,
          name: matchingProduct.name,
          subName: matchingProduct.subName,
          description: matchingProduct.description,
          servicePricePerWeek: "£0.00",
          servicePricePerMonth: "£0.00"
        })
      } else if (matchingProduct.options && matchingProduct.options.length > 0) {
        var matchingServiceOption = matchingProduct.options.find(
          _ => _.id === packageService.serviceOptionId
        )

        var optionCostPerWeek = 0
        var optionCostPerMonth = 0
        var optionCostPerYear = 0

        if (matchingServiceOption != null) {
          for (let i = 0; i < matchingServiceOption.products.length; i++) {
            var matchingServiceOptionProduct = matchingServiceOption.products[i]
            var optionProductCostPerWeek =
              matchingServiceOptionProduct.costPerWeek
            var optionProductCostPerMonth = matchingServiceOptionProduct.costPerMonth
              ? Number(matchingServiceOptionProduct.costPerMonth)
              : convertToMonthly(optionProductCostPerWeek)
            var optionProductCostPerYear = Number(
              matchingServiceOptionProduct.costPerYear
            )

            var serviceMultipliers =
              quotingToolMultipliers &&
              quotingToolMultipliers.length > 0 &&
              quotingToolMultipliers[0].filter(
                quotingToolMultiplier =>
                  quotingToolMultiplier.productId.toString() ===
                  matchingServiceOptionProduct.id.toString() &&
                  tenancyDetails[quotingToolMultiplier.fieldName] &&
                  tenancyDetails[quotingToolMultiplier.fieldName].toString() ===
                  quotingToolMultiplier.fieldValue
              )

            var allowanceCostPerWeek = 0
            var allowanceCostPerMonth = 0
            var allowanceCostPerYear = 0

            for (let j = 0; j < serviceMultipliers.length; j++) {
              optionProductCostPerWeek +=
                (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
                optionProductCostPerWeek
              optionProductCostPerMonth +=
                (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
                optionProductCostPerMonth
              optionProductCostPerYear +=
                (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
                optionProductCostPerYear
            }

            const numberOfSkyRooms = packageService.numberOfSkyRooms
            if (numberOfSkyRooms) {
              optionProductCostPerWeek = optionProductCostPerWeek * numberOfSkyRooms
              optionProductCostPerMonth = optionProductCostPerMonth * numberOfSkyRooms
              optionProductCostPerYear = optionProductCostPerYear * numberOfSkyRooms
            }

            if (packageService.allowance) {
              var numberOfBedrooms = isHomeownerOrRenter && tenancyDetails["NumberOfBillPayers"]
                ? tenancyDetails["NumberOfBillPayers"]
                : tenancyDetails["NumberOfTenants"]

              var allowanceTotal =
                matchingServiceOptionProduct.allowance *
                matchingServiceOptionProduct.sellRate1

              if (matchingServiceOptionProduct.sellRate2 > 0) {
                var averageNightUsage = 0.42;
                allowanceTotal = (matchingServiceOptionProduct.allowance * (1 - averageNightUsage) * matchingServiceOptionProduct.sellRate1) + (matchingServiceOptionProduct.allowance * averageNightUsage * matchingServiceOptionProduct.sellRate2);
              }

              allowanceCostPerWeek =
                Math.round((allowanceTotal / 52 / numberOfBedrooms) *
                  packageService.allowance)
              allowanceCostPerMonth +=
                Math.round((allowanceTotal / 12 / numberOfBedrooms) *
                  packageService.allowance)
              allowanceCostPerYear +=
                Math.round((allowanceTotal / numberOfBedrooms) * packageService.allowance)
            }

            optionProductCostPerWeek += allowanceCostPerWeek
            optionProductCostPerMonth += allowanceCostPerMonth
            optionProductCostPerYear += allowanceCostPerYear

            optionCostPerWeek += optionProductCostPerWeek
            optionCostPerMonth += optionProductCostPerMonth
            optionCostPerYear += optionProductCostPerYear
          }

          services.push({
            ...matchingServiceOption,
            displayOrder: matchingServiceOption.displayOrder,
            id: matchingProduct.id,
            name: matchingProduct.name,
            subName: matchingProduct.subName,
            description: matchingServiceOption.description,
            servicePricePerWeek: toPoundFormat(Number(optionCostPerWeek)),
            servicePricePerMonth: toPoundFormat(optionCostPerMonth),
            servicePricePerYear: toPoundFormat(optionCostPerYear),
            freeWithUnlimited: matchingProduct.freeWithUnlimited
          })

          weeklyTotal += roundNum(optionCostPerWeek)
          monthlyTotal += roundNum(optionCostPerMonth)
          yearlyTotal += roundNum(optionCostPerYear)
        }
      } else {
        var costPerWeek = matchingProduct.costPerWeek
        var costPerMonth = matchingProduct.costPerMonth
          ? Number(matchingProduct.costPerMonth)
          : convertToMonthly(costPerWeek)
        var costPerYear = Number(matchingProduct.costPerYear)


        if (matchingProduct.supplier === "Sky TV" && packageService.numberOfSkyRooms) {
          costPerMonth *= packageService.numberOfSkyRooms
          costPerWeek *= packageService.numberOfSkyRooms
          costPerYear *= packageService.numberOfSkyRooms
        }


        var serviceMultipliers =
          quotingToolMultipliers &&
          quotingToolMultipliers.length > 0 &&
          quotingToolMultipliers[0].filter(
            quotingToolMultiplier =>
              quotingToolMultiplier.productId.toString() ===
              matchingProduct.id.toString() &&
              tenancyDetails[quotingToolMultiplier.fieldName] &&
              tenancyDetails[quotingToolMultiplier.fieldName].toString() ===
              quotingToolMultiplier.fieldValue
          )

        var allowanceCostPerWeek = 0
        var allowanceCostPerMonth = 0
        var allowanceCostPerYear = 0

        for (let j = 0; j < serviceMultipliers.length; j++) {
          costPerWeek +=
            (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
            costPerWeek
          costPerMonth +=
            (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
            costPerMonth
          costPerYear +=
            (serviceMultipliers[j].pricingPercentageMultiplier / 100) *
            costPerYear
        }

        if (packageService.allowance) {
          allowanceCostPerWeek += costPerWeek * (packageService.allowance / 100)
          allowanceCostPerMonth +=
            costPerMonth * (packageService.allowance / 100)
          allowanceCostPerYear += costPerYear * (packageService.allowance / 100)
        }

        costPerWeek += allowanceCostPerWeek
        costPerMonth += allowanceCostPerMonth
        costPerYear += allowanceCostPerYear

        services.push({
          displayOrder: matchingProduct.displayOrder,
          name: matchingProduct.name,
          subName: matchingProduct.subName,
          description: matchingProduct.description,
          servicePricePerWeek: toPoundFormat(Number(costPerWeek)),
          servicePricePerMonth: toPoundFormat(costPerMonth),
          servicePricePerYear: toPoundFormat(costPerYear),
          freeWithUnlimited: matchingProduct.freeWithUnlimited
        })

        weeklyTotal += roundNum(costPerWeek)
        monthlyTotal += roundNum(costPerMonth)
        yearlyTotal += roundNum(costPerYear)
      }
    }
  })

  if (quote.gift1) {
    var matchingGift = quote.services.find(
      service => service.id === quote.gift1
    )

    if (matchingGift) {
      services.push({
        name: matchingGift.name,
        subName: matchingGift.subName,
        description: matchingGift.description,
        servicePricePerWeek: "£0.00",
        servicePricePerMonth: "£0.00"
      })
    }
  }

  return { weeklyTotal, monthlyTotal, yearlyTotal, services }
}

export function getMatchingProduct(services, productId) {
  for (let i = 0; i < services.length; i++) {
    const service = services[i]

    if (service.id === productId) {
      return service
    }

    for (let j = 0; j < service.products.length; j++) {
      let childProduct = service.products[j]
      if (childProduct.id === productId) {
        return childProduct
      }
    }

    for (let j = 0; j < service.options.length; j++) {
      for (let k = 0; k < service.options[j].products.length; k++) {
        let childChildProduct = service.options[j].products[k]
        if (childChildProduct.id === productId) {
          return childChildProduct
        }
      }
    }
  }

  return null
}

export function getMatchingQuotingToolVersion(data, toolId) {
  let matchingQuotingToolVersion
  let defaultToolId = data.allSiteSettings.edges[0].node.defaultQuoteToolLayout
    .toLowerCase()
    .replace(/_/, "-")

  if (
    (!toolId ||
      toolId.indexOf("sturents") !== -1 ||
      toolId.indexOf("hybr") !== -1) &&
    data.allSiteSettings &&
    data.allSiteSettings.edges[0].node
  ) {
    toolId = defaultToolId
  } else {
    matchingQuotingToolVersion = data.allQuotingToolVersion.edges
      .map(quotingToolVersion => quotingToolVersion.node)
      .find(quotingToolVersion => quotingToolVersion.versionId === toolId)

    if (!matchingQuotingToolVersion) {
      toolId = defaultToolId
    }
  }

  if (!matchingQuotingToolVersion) {
    matchingQuotingToolVersion = data.allQuotingToolVersion.edges
      .map(quotingToolVersion => quotingToolVersion.node)
      .find(quotingToolVersion => quotingToolVersion.versionId === toolId)
  }

  return matchingQuotingToolVersion
}

export function getMatchingQuotingToolVersionById(data, quotingToolVersionId) {
  let matchingQuotingToolVersion = null
  let toolId = null
  let defaultToolId = data.allSiteSettings.edges[0].node.defaultQuoteToolLayout
    .toLowerCase()
    .replace(/_/, "-")

  if (
    !quotingToolVersionId &&
    data.allSiteSettings &&
    data.allSiteSettings.edges[0].node
  ) {
    toolId = defaultToolId
  } else {
    matchingQuotingToolVersion = data.allQuotingToolVersion.edges
      .map(quotingToolVersion => quotingToolVersion.node)
      .find(
        quotingToolVersion => quotingToolVersion.id === quotingToolVersionId
      )

    if (!matchingQuotingToolVersion) {
      toolId = defaultToolId
    }
  }

  if (!matchingQuotingToolVersion) {
    matchingQuotingToolVersion = data.allQuotingToolVersion.edges
      .map(quotingToolVersion => quotingToolVersion.node)
      .find(quotingToolVersion => quotingToolVersion.versionId === toolId)
  }

  return matchingQuotingToolVersion
}
