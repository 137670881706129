import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
export default function SelectionButtons({
  selector,
  btn1Text,
  btn2Text,
  onClick1,
  onClick2,
  activeColor,
  activeFontColor,
  disabled
}) {
  const useStyles = makeStyles({
    buttons: {
      color: "#AEAEFF",
      display: "flex",
      flexDirection: "row",
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginTop: "2vh",
      marginBottom: "2vh",
    },
    selectedButton: {
      color: "#AEAEFF",
      backgroundColor: activeColor || "#08033D",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "26px",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 10%",
      borderRadius: "4px",
      cursor: "pointer",
      width: "45%",
      color: activeFontColor,
      margin: "0 5px",
      height: "100%",
    },
    unselectedButton: {
      opacity: 0.3,
      color: "#AEAEFF",
      backgroundColor: "#08033D",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "26px",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 10%",
      borderRadius: "4px",
      cursor: "pointer",
      width: "45%",
      margin: "0 5px",
      height: "100%",
    },
  })
  const styles = useStyles()
  return (
    <div className={styles.buttons}>
      <div
        className={clsx(
          selector === true || selector === btn1Text
            ? styles.selectedButton
            : styles.unselectedButton,
          selector === true || selector === btn1Text
            ? "quotingTool-yesNoSelected"
            : "quotingTool-yesNoUnselected",
        )}
        onClick={!disabled ? onClick1 : null}
        onKeyPress={!disabled ? onClick1 : null}
        role="button"
        tabIndex="0"
      >
        {btn1Text}
      </div>
      <div
        className={clsx(
          selector === false || selector === btn2Text
            ? styles.selectedButton
            : styles.unselectedButton,
          selector === false || selector === btn2Text
            ? "quotingTool-yesNoSelected"
            : "quotingTool-yesNoUnselected"
        )}
        onClick={!disabled ? onClick2 : null}
        onKeyPress={!disabled ? onClick2 : null}
        role="button"
        tabIndex="0"

      >
        {btn2Text}
      </div>
    </div>
  )
}
