import React, { useState } from "react"
import clsx from "clsx"
import Loadable from "react-loadable"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import NoSsr from "@material-ui/core/NoSsr"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "./Button"
import DoneIcon from "@material-ui/icons/Done"

import FormLabels from "./FormLabels"

const MuiPhoneNumber = Loadable({
  loader: () => import("material-ui-phone-number"),
  loading: CircularProgress,
})

const useStyles = makeStyles({
  primary: {
    width: "100%",
    borderRadius: "4px",
    display: "flex",
    flexGrow: "1",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#797979",
    "& .MuiInputBase-input": {
      padding: "16px 10px",
      "&::placeholder": {
        color: "#000",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: "#000 !important",
      background: "#000",
      borderBottom: "1px solid #f7c53d",
    },
    "& .Mui-disabled .MuiInputBase-input": {
      color: "#000000",
      opacity: 0.6,
    },
    "& .MuiInput-root": {
      color: "#000 !important",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#000", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000", // Solid underline on focus
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "inset",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#f44336",
    },
  },
  clearable: {
    width: "100%",
    "& .MuiInputBase-input": {
      paddingRight: "50px",
    },
  },
  warningMessage: {
    marginTop: "18px",
    color: "red",
    fontSize: "14px"
  }
})

export default function InputSection({
  children,
  onClear,
  onValidation,
  referralCodeIsValid,
  type,
  label,
  fieldIndex,
  placeholder,
  description,
  popupText,
  button,
  white,
  error,
  helperText,
  onChange,
  submitAttempted,
  autocomplete,
  required,
  isMoveInDate,
  name,
  value,
  restrictionMode,
  ...rest
}) {
  const [startedTyping, setStartedTyping] = useState(false)

  const classes = useStyles()
  const moveInDateMessage = "We can only accept contracts starting in the next 30 days."
  const tenancyDateMessage = "We can only accept contracts starting in the next 10 months"

  function checkMoveInDate() {
    if (!value) return false;

    const date = new Date();
    date.setDate(date.getDate() + 30);
    const lastAvailableDate = new Date();
    lastAvailableDate.setFullYear(lastAvailableDate.getFullYear() + 1);

    const valueDate = new Date(value);
    return valueDate > date && valueDate <= lastAvailableDate;
  }

  function checkTenancyStartDate() {
    if (!value) return false;

    const currentDate = new Date();
    const tenMonthsLater = new Date();
    tenMonthsLater.setMonth(currentDate.getMonth() + 10);

    const valueDate = new Date(value);
    return valueDate > tenMonthsLater || valueDate < currentDate;

  }
  if (type === "phone") {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <FormLabels
          label={label ? label + (required ? "*" : "") : ""}
          description={description}
          popupText={popupText}
          white={white}
          fieldIndex={fieldIndex}
        />
        <NoSsr>
          <MuiPhoneNumber
            className={classes.primary}
            disabled={restrictionMode}
            defaultCountry={"gb"}
            disableAreaCodes={true}
            variant="outlined"
            autoFormat={false}
            countryCodeEditable={false}
            error={(submitAttempted || startedTyping) && error}
            helperText={(submitAttempted || startedTyping) && helperText}
            value={value}
            // autoComplete="phone"
            {...rest}
          />
        </NoSsr>
      </div>
    )
  }

  if (onClear) {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <FormLabels
          label={label ? label + (required ? "*" : "") : ""}
          description={description}
          popupText={popupText}
          white={white}
          fieldIndex={fieldIndex}
        />
        <TextField
          className={classes.primary}
          placeholder={placeholder}
          type={type}
          variant="standard"
          label=""
          onChange={e => {
            onChange(e)
          }}
          onBlur={() => setStartedTyping(true)}
          error={(submitAttempted || startedTyping) && error}
          helperText={(submitAttempted || startedTyping) && helperText}
          value={value}
          disabled={restrictionMode}
          {...rest}
        >
          {children}
        </TextField>
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: 15,
            right: 10,
            color: "#000",
          }}
          onClick={() => onClear()}
          role="button"
          tabIndex="0"
        >
          <CloseIcon />
        </div>
      </div>
    )
  }
  if (name === "ReferralCode" && onValidation) {
    return (
      <div style={{ width: "100%" }} >
        <FormLabels
          label={label ? label + (required ? "*" : "") : ""}
          description={description}
          popupText={popupText}
          white={white}
          fieldIndex={fieldIndex}
        />
        <div style={{ display: "flex" }}>
          <TextField
            className={classes.primary}
            placeholder={"Referral Code"}
            type={type}
            label=""
            error={error}
            helperText={helperText}
            onChange={e => {
              onChange(e)
              setStartedTyping(true)
            }}
            value={value}
            {...rest}
            inputProps={{
              form: {
                autocomplete: "off",
              },
              ...rest.inputProps
            }}
          >
            {children}

          </TextField>
          {(referralCodeIsValid.checked && referralCodeIsValid.valid && !referralCodeIsValid.loading) &&
            (<div style={{ display: "flex", height: "50px", alignItems: "center" }}>
              <DoneIcon
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  borderRadius: "50%",
                  height: "20px",
                  width: "20px",
                  padding: "3px",
                }}
              />
            </div>)}
          <div style={{ marginLeft: 10 }}>
            <Button
              onClick={() => onValidation(value)}
              disabled={!value}
              loading={referralCodeIsValid.loading}
            >
              Validate
            </Button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ width: "100%" }}>
      <FormLabels
        label={label ? label + (required ? "*" : "") : ""}
        description={description}
        popupText={popupText}
        white={white}
        fieldIndex={fieldIndex}
      />
      <div style={{ display: "flex" }}>
        <TextField
          className={classes.primary}
          placeholder={placeholder}
          type={type}
          label=""
          error={(submitAttempted || startedTyping) && error}
          helperText={(submitAttempted || startedTyping) && helperText}
          onChange={e => {
            onChange(e)
            setStartedTyping(true)
          }}
          value={value}
          disabled={restrictionMode}
          {...rest}
          inputProps={{
            autocomplete: autocomplete || "new-password",
            form: {
              autocomplete: "off",
            },
            ...rest.inputProps
          }}
        >
          {children}
        </TextField>
        {button && button}
      </div>
      {(isMoveInDate && name !== "TenancyStart" && checkMoveInDate()) &&
        <div className={classes.warningMessage}>{moveInDateMessage}</div>
      }
      {(name === "TenancyStart") && checkTenancyStartDate() &&
        <div className={classes.warningMessage}>{tenancyDateMessage}</div>
      }

    </div>
  )
}
