import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import PageWrapper from "../../../components/PageWrapper"
import Button from "../../../components/button/Button"

import * as signUpSuccessStyles from "./signUpSuccess.module.css"

import SuccessImage from "./sign-up-success.png"

const useStyles = makeStyles({
  closeButton: {
    display: "inline-flex",
    maxWidth: "150px",
    width: "100%",
    marginLeft: 10,
    "&:hover": {
      textDecorationLine: "underline",
    },
    "@media (max-width: 450px)": {
      marginLeft: 0,
      marginTop: 10,
    },
  },
})

export default ({ location, groupId, hideOverviewButton }) => {
  const classes = useStyles()

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#FAFAFA"
      hideHeaderBorder={true}
    >
      <div
        className={signUpSuccessStyles.signUpSuccess}
        style={{ textAlign: "center" }}
      >
        <img
          alt="Signup success"
          className={signUpSuccessStyles.successImage}
          src={SuccessImage}
        />
        <div
          className={signUpSuccessStyles.successTitle}
          style={{ color: "#000" }}
        >
          You're all set!
        </div>
        <div
          className={signUpSuccessStyles.successDescription}
          style={{ color: "#000" }}
        >
          Thank you for setting up your payment information. You can return to
          the overview page to see how your housemates are progressing.
        </div>
        <div
          className={signUpSuccessStyles.successDescription}
          style={{ color: "#000" }}
        >
          Need help moving house? Get £15 off as a customer of Bunch <a href='https://www.anyvan.com/partners/the-bunch?credit-code=partnj4EMpartRCg9Fz9R' target="_blank" style={{ textDecorationLine: 'underline' }}>using AnyVan</a>.
        </div>
        <div
          className={signUpSuccessStyles.successDescription}
          style={{ color: "#000" }}
        >
          Your unique portal link will be sent to you via email / SMS within 24
          hours of the last tenant completing sign-up.
        </div>
        {!hideOverviewButton && (
          <Link
            className={signUpSuccessStyles.link}
            to={`/tenants/app/signup/complete/${groupId}`}
          >
            <Button
              title="Return to overview"
              style={{
                width: 250,
                display: "inline-flex",
              }}
            />
          </Link>
        )}
      </div>
    </PageWrapper>
  )
}
