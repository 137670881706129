/**
 * DropdownWithMultipleSelection Component
 *
 * Props:
 * - label (string): The label for the dropdown.
 * - description (string): Description text displayed alongside the label.
 * - popupText (string): Text displayed in a popup for additional information.
 * - value (array): The current selected values for the dropdown.
 * - setValue (function): Function to update the selected values.
 * - helperText (string): Helper text displayed below the dropdown input.
 * - labels (array): Array of string labels to be used as dropdown options.
 * - disabled (boolean): Boolean to enable or disable the dropdown.
 * - placeholder (string): Placeholder text for the dropdown input.
 * - fieldIndex (number): Index for the field, useful for form handling.
 *
 * Usage:
 * <DropdownWithMultipleSelection
 *     label="Example Label"
 *     description="This is a description"
 *     popupText="More info here"
 *     value={selectedValues}
 *     setValue={setSelectedValues}
 *     helperText="Helper text"
 *     labels={["Option 1", "Option 2", "Option 3"]}
 *     disabled={false}
 *     placeholder="Select options"
 *     fieldIndex={0}
 * />
 **/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormLabels from "../../components/FormLabels";


export default function DropdownWithMultipleSelection({
    label,
    description,
    popupText,
    value,
    setValue,
    helperText,
    error,
    labels,
    disabled,
    placeholder,
    fieldIndex,
}) {
    const useStyles = makeStyles(theme => ({
        root: {
            border: "1px solid #CCCCCC",
            backgroundColor: "#FAFAFA",
            borderRadius: "4px",
            display: "flex",
            flexGrow: "1",
            fontSize: "16px",
            lineHeight: "16px",
            fontFamily: "mundial",
            width: "100%",
            maxWidth: "100%",
            marginTop: "10px",
            "& .MuiInputBase-input": {
                padding: "10px",
            },
            "& .MuiChip-root": {
                backgroundColor: "#eeeeee"
            },
            "& .MuiAutocomplete-inputRoot": {
                padding: "5px"
            }
        },
        error: {
            border: "1px solid #F44336",
            color: "#F44336",
        },
        errorText: {
            color: "#F44336",
            fontSize: "12px",
            fontFamily: "mundial",
        },
        autocomplete: {
            "& .MuiInputBase-root": {
                backgroundColor: "#FAFAFA",
            },
            width: "100%",
            maxWidth: "100%",
            marginTop: "10px",
            marginBottom: "10px",
        },
    }));
    const classes = useStyles();

    const classesToApply = error
        ? `${classes.root} ${classes.error}`
        : classes.root

    const dropdownOptions = labels.map(label => ({
        value: label,
        label,
    }));

    const handleValueChange = (event, newValue) => {
        // Ensure unique values
        const uniqueValues = Array.from(new Set(newValue.map(item => item.value))).map(value => {
            return dropdownOptions.find(option => option.value === value);
        });
        setValue(uniqueValues);
    };

    return (
        <div  style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
                <FormLabels
                    label={label}
                    description={description}
                    popupText={popupText}
                    fieldIndex={fieldIndex}
                />
                <Autocomplete
                    multiple={true}
                    options={dropdownOptions}
                    getOptionLabel={(option) => option.label}
                    value={value}
                    onChange={handleValueChange}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder={placeholder}
                            className={classesToApply}
                            error={error}
                            disabled={disabled}
                        />
                    )}
                    className={classes.autocomplete}
                />
            </div>
            {error ? <div className={classes.errorText}>{helperText}</div> : null}

        </div>
    );
}
