import React from "react"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import Frame from "../frame/Frame"

import * as faqSectionStyles from "./FAQSection.module.css"

function FAQSection({
  section,
  setOpenSection,
  openSection,
  setOpenQuestion,
  openQuestion,
  color,
}) {
  return (
    <Frame title={section.title} description={section.description}>
      <div style={{ marginBottom: 20 }}>
        {openSection === section.title && (
          <div
            style={{ marginLeft: section.hideTitle && "0px" }}
            className={faqSectionStyles.faqSectionContent}
          >
            {section.questionsAndAnswers.map((questionAndAnswer, index) => {
              return (
                <div style={{ margin: "20px 0" }}>
                  <div
                    style={{
                      color:
                        openQuestion === index ? "#FAFAFA" : "rgb(247 197 62)",
                      background: openQuestion === index ? "#FF8469" : "#FAFAFA",
                    }}
                    className={faqSectionStyles.faqQuestion}
                    onClick={() => {
                      if (openQuestion === index) {
                        setOpenQuestion(null)
                      } else {
                        setOpenQuestion(index)
                      }
                    }}
                    onKeyPress={() => {
                      if (openQuestion === index) {
                        setOpenQuestion(null)
                      } else {
                        setOpenQuestion(index)
                      }
                    }}
                    tabIndex="0"
                    role="button"
                  >
                    <div
                      style={{
                        color:
                          openQuestion === index ? "#FAFAFA" : "#FF8469",
                      }}
                      className={faqSectionStyles.faqQuestionText}
                    >
                      {questionAndAnswer.question}
                    </div>
                  </div>
                  {openQuestion === index && (
                    <div
                      className={faqSectionStyles.faqAnswer}
                      style={{ marginBottom: 5, color }}
                    >
                      {questionAndAnswer.answer}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Frame>
  )
}

export default FAQSection
