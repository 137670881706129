import React from "react"
import ButtonBase from "@material-ui/core/ButtonBase"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

function Button(props) {
  const useStyles = makeStyles(() => ({
    primary: {
      backgroundColor: "#08033D",
      color: '#AEAEFF',
      height: "50px",
      width: props.fullWidth ? "100%" : "180px",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      borderRadius: "4px",
      lineHeight: "16px",
      border: "none",
      padding: "8px 22px",
      textDecorationLine: "none !important",
    },
    white: {
      backgroundColor: "#F45789",
      // padding: "17px 32px",
      width: "180px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FAFAFA",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "bold",
      borderRadius: "4px",
      border: "2px solid #243373",
      padding: "8px 22px",
      height: "50px",
    },
    red: {
      backgroundColor: "#ea5655",
      // padding: "17px 32px",
      width: "180px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "08033D",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "bold",
      borderRadius: "4px",
      padding: "8px 22px",
      height: "50px",
    },
    disabled: {
      opacity: 0.3,
    },
    black: {
      backgroundColor: "black",
      // padding: "12px 20px",
      color: "white",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "16px",
      border: "none",
    },
    round: {
      backgroundColor: "#FFFFFF",
      borderRadius: "50%",
      width: 42,
      height: 42,
      marginRight: 10,
      border: "1px solid #fff",
    },
    active: {
      backgroundColor: "rgba(48, 66, 146, 0.08)",
      borderRadius: "50%",
      border: "1px solid rgba(48, 66, 146, 0.5)",
      color: "#000000",
      width: 42,
      height: 42,
      marginRight: 10,
    },
    loading: {
      cursor: "not-allowed",
      color: "#AEAEFF"
    },
    small: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "14px",
      width: "100px",
      height: "40px",
    },
    "@media only screen and (max-width: 600px)": {
      primary: {
        color: "#AEAEFF",
        fontSize: "14px",
        fontFamily: "tenon, sans-serif",
        fontWeight: "bold",
        lineHeight: "14px",
        border: "none",
      },
      white: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "bold",
      },
      red: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "bold",
      },
      disabled: {
        cursor: "not-allowed",
        backgroundColor: "gray",
      },
      round: {
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: 42,
        height: 42,
        marginRight: 10,
      },
      active: {
        backgroundColor: "rgba(48, 66, 146, 0.08)",
        borderRadius: "50%",
        border: "1px solid rgba(48, 66, 146, 0.5)",
        color: "#000000",
        width: 42,
        height: 42,
        marginRight: 10,
      },
      loading: {
        cursor: "not-allowed",
      },
    },
  }))
  const classes = useStyles()
  const {
    children,
    className,
    small,
    white,
    red,
    onClick,
    disabled,
    secondary,
    loading,
    black,
    round,
    active,
    showShine,
    ...rest
  } = props
  return (
    <div style={{ position: "relative", width: "inherit" }}>
      {showShine && <div className="shine-box" />}
      <ButtonBase
        style={{ fontFamily: "tenon, sans-serif" }}
        onClick={onClick}
        className={clsx(
          classes.primary,
          {
            [classes.small]: small,
            [classes.white]: white,
            [classes.red]: red,
            [classes.loading]: loading,
            [classes.black]: black,
            [classes.round]: round,
            [classes.active]: active,
            [classes.disabled]: disabled,
          },
          className
        )}
        disabled={disabled || loading}
        {...rest}
      >
        {loading ? <CircularProgress size={25} color="#AEAEFF"/> : children}
      </ButtonBase>
    </div>
  )
}

export default Button
