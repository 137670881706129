import React, { useEffect } from "react"
import TrustpilotFooter from "../components/TrustpilotFooter"

import PageWrapper from "../../../components/PageWrapper"

import { createHomeownerLead as apiCreateHomeownerLead } from "../../../services/tenantsApi"

import HomeownerSignupFormContainer from "./containers/HomeownerSignupFormContainer"

function HomeownerSignupPage({ currentQueryStrings, location }) {
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState([])
  const [success, setSuccess] = React.useState(false)

  const createHomeownerLead = async payload => {
    setErrors([])

    try {
      setLoading(true)

      const data = await apiCreateHomeownerLead({
        tenancyDetails: JSON.stringify({
          FirstName: payload.firstName,
          LastName: payload.lastName,
          Email: payload.email,
          Phone: payload.phoneNumber,
          City: payload.city,
          HouseholdType: payload.householdType,
        }),
        utm_source: currentQueryStrings.utm_source,
        utm_campaign: currentQueryStrings.utm_campaign,
        utm_medium: currentQueryStrings.utm_medium,
        utm_term: currentQueryStrings.utm_term,
        utm_content: currentQueryStrings.utm_content,
        referral_code:
          currentQueryStrings.fpr || currentQueryStrings.referral_code
      })

      if (data.success) {
        // TODO: What to do here
        setSuccess(true)
      } else if (data.errors) {
        setErrors([data.errors[0].reason])
      } else {
        setErrors(["An error occurred trying to invite tenant"])
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setErrors([e.message])
    }
  }

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#FAFAFA"
      hideHeaderBorder={true}
    >
      <PersonalInformationTab
        onContinue={payload => {
          createHomeownerLead(payload)
        }}
        submitting={loading}
        success={success}
      />
      <TrustpilotFooter />
    </PageWrapper>
  )
}

function PersonalInformationTab({ onContinue, submitting, success }) {
  const [personalInformation, setPersonalInformation] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  })

  return (
    <HomeownerSignupFormContainer
      primary={false}
      skipPostPersonalInformation={true}
      personalInformation={personalInformation}
      onContinue={() => onContinue(personalInformation)}
      setPersonalInformation={update =>
        setPersonalInformation(h => ({ ...h, ...update }))
      }
      submitting={submitting}
      success={success}
    />
  )
}

export default HomeownerSignupPage
