import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import {
    Wrapper,
} from "../components"
import { getMatchingQuotingToolVersion } from "../services/utils"

import StudentIcon from "./student-icon.png"
import ProfessionalIcon from "./renter-icon.png"

const useStyles = makeStyles(theme => ({
    customerType: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: "100px 20px",
        flexDirection: "column",
        maxWidth: "1000px",
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    customerTypeButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: "20px",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            display: "block",
            width: "100%",
        },
    },
    customerTypeButton: {
        height: "300px",
        width: "400px",
        padding: "30px",
        border: "1px solid #FAFAFA",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "20px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row-reverse",
            width: "100%",
            height: "130px",
            padding: "10px",
            justifyContent: "flex-end",
            marginBottom: "20px",
        },
    },
    customerTypeButtonText: {
        color: "#AEAEFF",
        fontWeight: "normal",
        fontSize: 18,
        fontFamily: "tenon, sans-serif",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 15,
            fontSize: 20,
        },
    },
    customerTypeButtonImage: {
        height: "calc(100% - 24px)",
    },
}))

export default function QuotePage({
    currentQueryStrings,
    toolId,
    quoteId,
    location,
    siteData,
}) {
    const classes = useStyles()

    let matchingQuotingToolVersion = getMatchingQuotingToolVersion(
        siteData,
        toolId
    )

    const queryStrings = location.search;

    const renderContent = () => {
        return (
            <Wrapper
                location={location}
                matchingQuotingToolVersion={matchingQuotingToolVersion}
                group={{ quote: {} }}
                toolId={toolId}
                quoteId={quoteId}
                backgroundColor="#08033D"
                fullScreen
            >
                <div className={classes.customerType}>
                    <div style={{ fontFamily: "tenon, sans-serif",fontSize: 30, fontWeight: 800, marginBottom: 30, color: "#AEAEFF" }}>
                        Are you a...
                    </div>
                    <div className={classes.customerTypeButtons}>
                        <Link
                            to={`/tenants/app/tool-a/signup${queryStrings}`}
                            >
                            <div
                                className={classes.customerTypeButton}
                            >
                                <div className={classes.customerTypeButtonText}>Student</div>
                                <img
                                    className={classes.customerTypeButtonImage}
                                    src={StudentIcon}
                                />
                            </div>
                        </Link>
                        <Link
                            to={`/tenants/app/residential/signup${queryStrings}`}
                            >
                            <div className={classes.customerTypeButton}>
                                <div className={classes.customerTypeButtonText}>
                                    Residential
                                </div>
                                <img
                                    className={classes.customerTypeButtonImage}
                                    src={ProfessionalIcon}
                                    style={{ rotate: "60deg" }}
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            </Wrapper>
        )
    }

    return (
        <>
            {matchingQuotingToolVersion.customCSSURL && (
                <link
                    rel="stylesheet"
                    type="text/css"
                    href={matchingQuotingToolVersion.customCSSURL}
                />
            )}
            {renderContent()}
        </>
    )
}
