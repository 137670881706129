import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { StaticQuery, graphql, navigate } from "gatsby"
import { Router, Redirect } from "@reach/router"
import qs from "qs"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import PrivateRoute from "../../portals/tenants/routeTemplates/privateRoute"
import MeterReadingsOnlyRoute from "../../portals/tenants/routeTemplates/meterReadingsOnlyRoute"
import UpdatePaymentDetailsOnlyRoute from "../../portals/tenants/routeTemplates/updatePaymentDetailsOnlyRoute"

import TenantPlans from "../../portals/tenants/plans"
import CallbackRequest from "../../portals/tenants/callback-request"

import Login from "../../portals/tenants/login"
import AdminLoginSuccess from "../../portals/tenants/adminLoginSuccess"
import TenanciesSelect from "../../portals/tenants/index"
import AcceptInvite from "../../portals/tenants/acceptInvite"
import ForgotPassword from "../../portals/tenants/forgotPassword"
import ResetPassword from "../../portals/tenants/resetPassword"
import VerifyAccount from "../../portals/tenants/verifyAccount"
import MeterReadingRequest from "../../portals/tenants/meterReadingRequest"
import UpdatePaymentDetailsRequest from "../../portals/tenants/updatePaymentDetailsRequest"
import UpdateBankDetailsRequest from "../../portals/tenants/updateBankDetailsRequest"
import EditProfile from "../../portals/tenants/editProfile"
import EditBasicProfile from "../../portals/tenants/editBasicProfile"
import UpdateBankDetails from "../../portals/tenants/updateBankDetails"
import Favourites from "../../portals/tenants/favourites"
import TenancyInformation from "../../portals/tenants/tenancyInformation"
import Usage from "../../portals/tenants/usage"
import MeterReadings from "../../portals/tenants/meterReadings"
import Meters from "../../portals/tenants/meters"
import ShareAndEarn from "../../portals/tenants/shareAndEarn"

import Quote from "../../portals/quotingTool/quote/QuotePage"
import QuoteType from "../../portals/quotingTool/quote/QuoteTypePage"
import StartQuote from "../../portals/quotingTool/quote/StartQuotePage"
import BroadbandCheckPage from "../../portals/quotingTool/quote/BroadbandCheckPage"
import HomeownerSignupPage from "../../portals/quotingTool/quote/HomeownerSignupPage"
import SignUpPage from "../../portals/quotingTool/signup/SignupPage"
import LeadPage from "../../portals/quotingTool/signup/LeadPage"
import NewHousematePage from "../../portals/quotingTool/signup/NewHousematePage"
import SignUpSuccessPage from "../../portals/quotingTool/signup/SignUpSuccess"
import ResignPage from "../../portals/quotingTool/signup/ResignPage"
import Bills from "../../portals/tenants/bills"
import Invoices from "../../portals/tenants/invoices"
import Overview from "../../portals/tenants/overview"
import MoveInOutProgress from "../../portals/tenants/moveInOutProgress"
import FindQuotePage from "../../portals/quotingTool/quote/FindQuotePage"

// These pages are for tenants added to households that were created outside of the quoting tool
import InviteNewTenantPage from "../../portals/quotingTool/signup/InviteNewTenantPage"
import AcceptTenantInvitePage from "../../portals/quotingTool/signup/AcceptTenantInvitePage"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const App = () => {
  const queryClient = new QueryClient()

  let currentQueryStrings = {}

  let currentQueryStringsStr =
    typeof window !== "undefined" &&
    window.localStorage.getItem("theBunch-queryStrings")

  if (currentQueryStringsStr) {
    try {
      currentQueryStrings = JSON.parse(currentQueryStringsStr)
    } catch (e) {
      console.log(e)
    }
  }

  const newQueryStrings =
    typeof window !== "undefined" &&
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
      decoder: function (str, defaultDecoder, charset, type) {
        if (type === "key" && str.toLowerCase().startsWith("utm")) {
          return str.toLowerCase()
        }
        return str
      },
    })

  if (newQueryStrings) {
    currentQueryStrings = {
      ...currentQueryStrings,
      ...newQueryStrings,
    }
  }

  typeof window !== "undefined" &&
    window.localStorage.setItem(
      "theBunch-queryStrings",
      JSON.stringify(currentQueryStrings)
    )

  const renderContent = data => {
    return (
      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripePromise}>
          <Router
            basepath="/tenants/app"
            style={{ height: "100%", width: "100%" }}
          >
            <TenantPlans path="/plans" type="contact" />
            <TenantPlans path="/lead-plans" type="lead" />
            <CallbackRequest path="/callback-request" />
            <Login path="/login" />
            <AdminLoginSuccess path="/admin-login/success" />
            <AcceptInvite path="/accept-invite/:code" />
            <ForgotPassword path="/forgot-password" />
            <ResetPassword path="/reset-password/:code" />
            <VerifyAccount path="/verify-account/:code" />
            <MeterReadingRequest path="/meter-reading-request/:meterReadingRequestToken" />
            <UpdatePaymentDetailsRequest path="/update-payment-details/:updatePaymentDetailsToken" />
            <UpdateBankDetailsRequest path="/update-bank-details/:updatePaymentDetailsToken" />
            <PrivateRoute component={TenanciesSelect} path="/tenancies" />
            <PrivateRoute component={Favourites} path="/favourites" />
            <PrivateRoute
              component={EditProfile}
              path="/tenancies/:tenancyId/account-details"
            />
            <PrivateRoute
              component={EditBasicProfile}
              path="/account-details"
            />
            <PrivateRoute component={Overview} path="/tenancies/:tenancyId/overview" />
            <PrivateRoute
              component={Overview}
              path="/tenancies/:tenancyId/overview"
            />
            <PrivateRoute
              component={MoveInOutProgress}
              path="/tenancies/:tenancyId/move-in-out-status"
            />
            <PrivateRoute
              component={Bills}
              path="/tenancies/:tenancyId/bills"
            />
            <PrivateRoute
              component={Invoices}
              path="/tenancies/:tenancyId/invoices"
            />
            <PrivateRoute
              component={TenancyInformation}
              path="/tenancies/:tenancyId/tenancy-information"
            />
            <PrivateRoute
              component={Usage}
              path="/tenancies/:tenancyId/usage"
            />
            <PrivateRoute
              component={Meters}
              path="/tenancies/:tenancyId/meters"
            />
            <PrivateRoute
              component={UpdateBankDetails}
              path="/tenancies/:tenancyId/bank-details"
            />
            <PrivateRoute
              component={MeterReadings}
              path="/tenancies/:tenancyId/meter-readings"
            />
            <PrivateRoute component={ShareAndEarn} path="/share-and-earn" />
            <MeterReadingsOnlyRoute
              component={Meters}
              path="/meter-reading-request/:tenancyId/meters"
            />
            <UpdatePaymentDetailsOnlyRoute
              component={UpdateBankDetails}
              path="/update-payment-details-request/:tenancyId/bank-details"
            />
            <Quote
              path="/quote/:quoteId"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
              restrictionMode={true} // Disable form fields editing (contact details)
            />
            <Quote
              path="/:toolId/quote/:quoteId"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
              restrictionMode={true} // Disable form fields editing (contact details)
            />
            <Quote
              path="/:toolId/signup/:quoteId"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
              restrictionMode={true} // Disable form fields editing (contact details)
            />
            <Quote
              path="/:toolId/quote"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <Quote
              path="/:toolId/signup"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <QuoteType
              path="/quote"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <QuoteType
              path="/signup"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <StartQuote
              path="/:toolId/quote-start"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <StartQuote
              path="/quote-start"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <FindQuotePage path="/signup/find" />
            <BroadbandCheckPage
              path="/check-broadband-availability"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <SignUpPage path="/sign-up/:groupId" siteData={data} />
            <SignUpPage path="/:toolId/sign-up/:groupId" siteData={data} />
            <SignUpPage path="/signup/complete/:groupId" siteData={data} />
            <SignUpPage path="/:toolId/signup/complete/:groupId" siteData={data} />
            <LeadPage
              path="/:toolId/sign-up/:groupId/:leadId"
              siteData={data}
            />
            <LeadPage path="/sign-up/:groupId/:leadId" siteData={data} />
            <LeadPage
              path="/:toolId/signup/complete/:groupId/:leadId"
              siteData={data}
            />
            <LeadPage path="/signup/complete/:groupId/:leadId" siteData={data} />
            <SignUpSuccessPage
              path="/sign-up/:groupId/sign-up-success"
              siteData={data}
            />
            <SignUpSuccessPage
              path="/signup/complete/:groupId/success"
              siteData={data}
            />
            <SignUpSuccessPage
              path="/new-tenant/:groupId/sign-up-success"
              hideOverviewButton={true}
              siteData={data}
            />
            <InviteNewTenantPage path="/new-tenant/:tenancyId/invite" />
            <AcceptTenantInvitePage path="/new-tenant/:inviteId/accept" />
            <NewHousematePage path="/sign-up/:groupId/new-housemate" />
            <NewHousematePage path="/:toolId/sign-up/:groupId/new-housemate" />
            <NewHousematePage path="/signup/:groupId/new-housemate" />
            <NewHousematePage path="/:toolId/signup/:groupId/new-housemate" />
            <HomeownerSignupPage
              path="/homeowners"
              currentQueryStrings={currentQueryStrings}
              siteData={data}
            />
            <ResignPage path="/resign/:resignAuthToken" siteData={data} />
            <Redirect noThrow exact from="/" to="/tenants/app/tenancies" />
          </Router>
        </Elements>
      </QueryClientProvider>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query GetQuotingToolVersion {
          allQuotingToolVersion {
            edges {
              node {
                id
                name
                versionId
                type
                logoImageUrl
                topBarBackgroundHexCode
                activePageStepHexCode
                activePageStepImage
                completedPageStepHexCode
                incompletePageStepHexCode
                energyAllowanceProductId
                energyAllowanceProductIds
                signupTitle
                signupDescription
                signupQuoteTitle
                signupQuoteDescription
                signupQuoteInfoText
                signupTenancyDetailsTitle
                signupTenancyDetailsDescription
                signupTenancyDetailsInfoText
                signupTenantsTitle
                signupTenantsDescription
                signupTenantsInfoText
                signupSignupLaterTitle
                signupSignupLaterDescription
                signupSignupLaterInfoText
                signupSignupLaterButtonText
                queueJumpFeatures
                standardFeatures
                benefits
                benefitImages
                nonTrackingQuotes
                customCSSURL
                showTrustpilotWidget
                energyMandatory
                paymentPageTitle
                paymentPageDescription
                paymentPageTestPaymentMessage
                paymentPageFirstPaymentMessage
                energyAllowanceControl {
                  name
                  productAllowanceOptions {
                    name
                    value
                    label
                    imageUrl
                  }
                }
                quotingToolPages {
                  id
                  name
                  stepTitle
                  title
                  description
                  pageNumber
                  submitButtonText
                  quotingToolPageFields {
                    name
                    title
                    description
                    order
                    type
                    title
                    description
                    infoText
                    emptyErrorText
                    invalidErrorText
                    minimumValue
                    maximumValue
                    defaultValue
                    products
                    required
                    dropdownOptions
                    showServicePrices
                    showWeeklyPrice
                    showMonthlyPrice
                    showYearlyPrice
                    rangeUnits
                    autocomplete
                    maximumValueFormula
                    dependsOnField
                    dependsOnFieldValue
                    quotingToolMultipliers {
                      productId
                      fieldName
                      fieldValue
                      pricingPercentageMultiplier
                    }
                  }
                }
              }
            }
          }
          allSiteSettings {
            edges {
              node {
                id
                defaultQuoteToolLayout
                showLiveChat
                showLiveChat
                enableTrustPilotCarousel
              }
            }
          }
        }
      `}
      render={data => renderContent(data)}
    />
  )
}

export default App
