import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Slider as MuiSlider } from "@material-ui/core"
import clsx from "clsx"

import FormLabels from "./FormLabels"

const useStyles = makeStyles({
  primary: {
    "@media only screen and (max-width: 600px)": {
      maxWidth: "250px",
    },
    "& .MuiSlider-track": {
      border: "none",
      height: 15,
      borderRadius: "7.5px",
      backgroundColor: "#ffffff",
      opacity: 1,
    },
    "& .MuiSlider-rail": {
      border: "none",
      height: 15,
      borderRadius: "7.5px",
      backgroundColor: "#ffffff",
      opacity: 1,
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#FAFAFA",
      border: "2px solid currentColor",
      marginLeft: -13,
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(50% - 16px)",
    },
    "& .MuiSlider-markLabel": {
      top: "35px",
      maxWidth: "40px",
      whiteSpace: "pre-wrap",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      color: "#FAFAFA",
      fontSize: 12,
      "@media only screen and (max-width: 1370px)": {
        fontSize: "11px",
        top: "50px",
      },
    },
    "& .MuiSlider-mark": {
      display: "none",
    },
  },
})

export default function Slider({
  value,
  onChange,
  fieldIndex,
  label,
  description,
  popupText,
  min,
  max,
  marks,
  disabled,
  white,
}) {
  const classes = useStyles()

  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      <FormLabels
        label={label}
        description={description}
        popupText={popupText}
        white={white}
        fieldIndex={fieldIndex}
      />
      <div
        style={{
          display: "flex",
          maxWidth: 400,
          padding: "0px 10px",
          margin: "0px auto",
        }}
      >
        <MuiSlider
          className={clsx("quoteTool-slider", classes.primary)}
          defaultValue={1}
          value={value}
          onChange={(e, val) => onChange(val)}
          // getAriaValueText={serviceToDisplay.options[]}
          aria-labelledby="discrete-slider-small-steps"
          step={1}
          min={min}
          max={max}
          marks={marks}
          valueLabelDisplay="off"
          disabled={disabled}
        />
      </div>
    </div>
  )
}
