import React from "react"

import * as sectionHeaderStyles from "./sectionHeader.module.css"

function SectionHeader({ title, description }) {
  return (
    <div className={sectionHeaderStyles.sectionHeaderWrapper}>
      <div style={{fontFamily: "tenon, sans-serif"}} className={sectionHeaderStyles.sectionHeader}>{title}</div>
      <div className={sectionHeaderStyles.sectionDescription}>
        {description}
      </div>
    </div>
  )
}

export default SectionHeader
