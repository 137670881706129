import React from "react"
import { Link } from "gatsby"
import Paper from "@material-ui/core/Paper"

import SecondaryButton from "../secondaryButton/SecondaryButton"

import * as appTitleStyles from "./appTitle.module.css"

export default function AppTitle({
  title,
  secondaryActionButtons,
  tableHeading,
  description,
}) {
  return (
    <Paper elevation={3}>
      <div
        className={appTitleStyles.appTitleWrapper}
        style={{ fontFamily: "tenon, sans-serif", padding: tableHeading ? 20 : 15 }}
      >
        <div>
          <div className={appTitleStyles.appTitleContent}>
            <div className={appTitleStyles.appTitle}
            style={{fontFamily: "tenon, sans-serif"}}>{title}</div>
            {secondaryActionButtons && (
              <div className={appTitleStyles.secondaryActionButtons}
              style={{fontFamily: "tenon, sans-serif"}}>
                {secondaryActionButtons.map(secondaryActionButton => {
                  return (
                    <SecondaryButton
                      title={secondaryActionButton.title}
                      to={secondaryActionButton.to}
                      onClick={() =>
                        secondaryActionButton.onClick &&
                        secondaryActionButton.onClick()
                      }
                    />
                  )
                })}
              </div>
            )}
          </div>
          <div>
            <div className={appTitleStyles.appDescription} style={{fontFamily: "tenon, sans-serif"}}>{description}</div>
          </div>
        </div>
      </div>
    </Paper>
  )
}
