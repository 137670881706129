import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "30px 20px",
    flexWrap: "wrap",
    width: "100%",
  },
  focusedInput: {
    backgroundColor: "#F45789",
    "& *": {
      color: '#fff !important'
    }
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      display: "flex",
      justifyContent: "flex-start",
      padding: "20px 20px",
      width: "100%",
    },
  },
}))

export default function FormLine(props) {
  const classes = useStyles()
  const { children, focusedInput } = props

  return (
    <div
      className={clsx(classes.root, "quotingTool-formField", {
        [classes.focusedInput]: focusedInput,
      })}
    >
      {children}
    </div>
  )
}
