import React, { useState } from "react"

import "../../../node_modules/react-vis/dist/style.css"
import {
  FlexibleWidthXYPlot,
  MarkSeries,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  DiscreteColorLegend,
  Hint,
} from "react-vis"
import { convertToCurrency } from "../../util/finance"

// const UsageGraph = ({ data }) => {
//   const graphData = {
//     options: {
//       chart: {
//         id: "basic-line",
//         toolbar: {
//           show: false,
//         },
//         zoom: {
//           enabled: false,
//         },
//         type: "column",
//         // stacked: true,
//       },
//       xaxis: {
//         categories: data.map(dataPoint =>
//           new Date(dataPoint.month).toLocaleDateString("en-GB", { month: '2-digit', year: 'numeric' })
//         ),
//         labels: {
//           rotate: -45,
//         },
//       },
//       stroke: {
//         width: [3, 3],
//         // curve: "smooth",
//         // dashArray: [15, 0],
//         colors: ["#9f9f9f", "#f7c53e"],
//       },
//       colors: ["#9f9f9f", "#f7c53e"],
//       dataLabels: {
//         enabled: false,
//       },
//       markers: {
//         colors: ["#9f9f9f", "#f7c53e"],
//         // size: 4,
//       },
//     },
//     series: [
//       {
//         name: "Expected (£)",
//         data: data.map(dataPoint => dataPoint.expectedUsage),
//         type: "column",
//       },
//       {
//         name: "Usage (£)",
//         data: data.map(dataPoint => dataPoint.actualUsage),
//         type: "column",
//       },
//       {
//         name: "Overusage Surcharge (£)",
//         data: data.map(dataPoint => dataPoint.additionalUsage),
//         type: "column",
//       },
//     ],
//   }

//   return (
//     <Chart options={graphData.options} series={graphData.series} width="100%" />
//   )
// }

// export default UsageGraph

const UsageGraph = ({ data }) => {
  const [hoverValue1, setHoverValue1] = useState(null)
  const [hoverValue2, setHoverValue2] = useState(null)
  const [hoverValue3, setHoverValue3] = useState(null)

  console.log(hoverValue3)
  return (
    <>
      <FlexibleWidthXYPlot
        className="clustered-stacked-bar-chart-example"
        xType="ordinal"
        stackBy="y"
        height={350}
        margin={{ bottom: 50 }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-45} style={{ marginBottom: 100, height: 200 }} />
        <YAxis />
        <MarkSeries
          cluster="usage"
          color="#FF8469"
          data={data.map(dataPoint => ({
            x: new Date(dataPoint.month).toLocaleDateString("en-GB", { month: '2-digit', year: 'numeric' }),
            y: dataPoint.actualUsage,
          }))}
          onNearestX={(value, { index }) => setHoverValue1(value)}
          onSeriesMouseOut={() => setHoverValue1(null)}
        />
        <MarkSeries
          cluster="predicted"
          color="#aca696"
          data={data.map(dataPoint => ({
            x: new Date(dataPoint.month).toLocaleDateString("en-GB", { month: '2-digit', year: 'numeric' }),
            y: dataPoint.expectedUsage,
          }))}
          onNearestX={(value, { index }) => setHoverValue2(value)}
          onSeriesMouseOut={() => setHoverValue2(null)}
        />
        {/* <MarkSeries
          cluster="predicted"
          color="#79C7E3"
          data={data.map(dataPoint => ({
            x: new Date(dataPoint.month).toLocaleDateString("en-GB", { month: '2-digit', year: 'numeric' }),
            y: dataPoint.additionalUsage,
          }))}
          onNearestX={(value, { index }) => setHoverValue3(value)}
          onSeriesMouseOut={() => setHoverValue3(null)}
        /> */}
        {(hoverValue1 || hoverValue2 || hoverValue3) && (
          <Hint
            value={hoverValue1 || {}}
            style={{
              fontSize: 14,
              background: "#3d3d3d91",
              padding: 5,
              borderRadius: 5,
            }}
          >
            <div style={{ color: "#FAFAFA" }}>
              {hoverValue1 && (
                <div>
                  <div>Current Usage</div>
                  <div>£{convertToCurrency(hoverValue1.y)}</div>
                </div>
              )}
              {hoverValue2 && (
                <div>
                  <div>Predicted Usage</div>
                  <div>£{convertToCurrency(hoverValue2.y)}</div>
                </div>
              )}
              {hoverValue3 && (
                <div>
                  <div>Overusage Surcharge</div>
                  <div>
                    £{convertToCurrency(hoverValue3.y - hoverValue3.y0)}
                  </div>
                </div>
              )}
            </div>
          </Hint>
        )}
      </FlexibleWidthXYPlot>

      <DiscreteColorLegend
        orientation="horizontal"
        items={[
          {
            title: "Current Usage",
            color: "#FF8469",
          },
          {
            title: "Predicted Usage",
            color: "#aca696",
          },
        ]}
      />
    </>
  )
}

export default UsageGraph
